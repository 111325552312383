<template>
  <img :src="logo" v-bind="$attrs" v-on="$listeners" alt="IG BCE BWS" />
</template>

<script>
import logo from "@assets/img/igbce-bws-logo-simple-default.svg";

export default {
  name: "BaseLogoMain",

  computed: {
    logo() {
      return logo;
    },
  },
};
</script>
