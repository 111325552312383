import apiClient from "@/api/modules/base";
import ROUTE from "./routes";

/**
 * Retrieve a list with user invitations
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.perPage
 * @param {number} params.page
 * @param {string} params.sortBy
 * @param {boolean} params.sortDesc
 * @param {string} params.search
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listUserInvitations = (params) =>
  apiClient.get(ROUTE.USER_INVITATIONS.INDEX, { params });

/**
 * Retrieve a specified user invitation
 *
 * @method
 * @memberOf userManagement
 * @param {number} userInvitationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showUserInvitation = (userInvitationId) =>
  apiClient.get(ROUTE.USER_INVITATIONS.SHOW, { params: { invitationId: userInvitationId } });

/**
 * Store a new user invitation
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {string} params.salutation
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {string} params.email
 * @param {number} params.personId
 * @param {Array<Number>} params.roleIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const storeUserInvitation = (params) =>
  apiClient.post(ROUTE.USER_INVITATIONS.STORE, { ...params });

/**
 * Update an user invitation
 *
 * @method
 * @memberOf userManagement
 * @param {object} params
 * @param {number} params.invitationId
 * @param {string} params.salutation
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {string} params.email
 * @param {string} params.navisionNumber
 * @param {array<number>} params.permissionIds
 * @param {array<number>} params.roleIds
 * @param {array<number>} params.userGroupIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateUserInvitation = (params) =>
  apiClient.put(ROUTE.USER_INVITATIONS.UPDATE, { ...params });

/**
 * Delete a user invitaion
 *
 * @method
 * @memberOf userManagement
 * @param {number} userInvitationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteUserInvitation = (userInvitationId) =>
  apiClient.delete(ROUTE.USER_INVITATIONS.DELETE, {
    data: { invitationId: userInvitationId },
  });

/**
 * Resend a user invitation
 *
 * @method
 * @memberOf userManagement
 * @param {number} invitationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const resendUserInvitation = (invitationId) =>
  apiClient.put(ROUTE.USER_INVITATIONS.RESEND, { invitationId });
