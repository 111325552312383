import createRedirect from "@helper/router/createRedirect";

export default [
  {
    path: "help/management",
    name: "HelpManagement",
    redirect: createRedirect(
      "https://polargold.atlassian.net/wiki/external/OTBiZDc4NzkxM2M2NDgyNzkzYzFkOWMzYTg2ZjMzM2Y"
    ),
    meta: {
      permissionNames: ["help.management"],
      menuNames: ["sideNavigation"],
      label: "NAVIGATION.SIDE_NAVIGATION.HELP.MANAGEMENT",
      icon: "far fa-question-circle",
    },
  },
  {
    path: "help/speakers",
    name: "HelpSpeakers",
    redirect: createRedirect(
      "https://polargold.atlassian.net/wiki/external/Yjk1ZGVjNTk1NjE2NGZmOGI0Nzk2ODkxZGEzNjk3NDc"
    ),
    meta: {
      permissionProps: ["isSpeaker"],
      permissionNames: ["help.speaker"],
      menuNames: ["sideNavigation"],
      label: "NAVIGATION.SIDE_NAVIGATION.HELP.SPEAKERS",
      icon: "far fa-question-circle",
    },
  },
];
