const Navision = () => import("@views/Restricted/Navision");

const ListImportsSeminar = () =>
  import("@views/Restricted/Navision/Imports/List/Seminar/index.vue");

const ListImportsDocument = () =>
  import("@views/Restricted/Navision/Imports/List/Document/index.vue");

const ListImportsContact = () =>
  import("@views/Restricted/Navision/Imports/List/Contact/index.vue");

const ListImportsContactPerson = () =>
  import("@views/Restricted/Navision/Imports/List/ContactPerson/index.vue");

const ListImportsSpeakerStatus = () =>
  import("@views/Restricted/Navision/Imports/List/SpeakerStatus/index.vue");

export default [
  {
    path: "/navision",
    component: Navision,
    name: "Navision",
    redirect: "/navision/imports/list/seminar",
    meta: {
      permissionNames: ["unitop.import.read"],
      menuNames: ["sideNavigation"],
      label: "NAVIGATION.SIDE_NAVIGATION.NAVISION.PARENT",
      icon: "far fa-cogs",
      order: -50,
    },
    children: [
      {
        path: "imports/list/seminar",
        name: "ImportsListSeminar",
        component: ListImportsSeminar,
        meta: {
          permissionNames: ["unitop.import.read"],
          label: "NAVIGATION.SIDE_NAVIGATION.NAVISION.IMPORTS.SEMINAR",
          menuNames: ["sideNavigation"],
        },
      },

      {
        path: "imports/list/document",
        name: "ImportsListDocument",
        component: ListImportsDocument,
        meta: {
          permissionNames: ["unitop.import.read"],
          label: "NAVIGATION.SIDE_NAVIGATION.NAVISION.IMPORTS.DOCUMENT",
          menuNames: ["sideNavigation"],
        },
      },

      {
        path: "imports/list/contact",
        name: "ImportsListContact",
        component: ListImportsContact,
        meta: {
          permissionNames: ["unitop.import.read"],
          label: "NAVIGATION.SIDE_NAVIGATION.NAVISION.IMPORTS.CONTACT",
          menuNames: ["sideNavigation"],
        },
      },

      {
        path: "imports/list/contact-person",
        name: "ImportsListContactPerson",
        component: ListImportsContactPerson,
        meta: {
          permissionNames: ["unitop.import.read"],
          label: "NAVIGATION.SIDE_NAVIGATION.NAVISION.IMPORTS.CONTACT_PERSON",
          menuNames: ["sideNavigation"],
        },
      },

      {
        path: "imports/list/speaker-status",
        name: "ImportsListSpeakerStatus",
        component: ListImportsSpeakerStatus,
        meta: {
          permissionNames: ["unitop.import.read"],
          menuNames: ["sideNavigation"],
          label: "NAVIGATION.SIDE_NAVIGATION.NAVISION.IMPORTS.SPEAKER_STATUS",
        },
      },
    ],
  },
];
