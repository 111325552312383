var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseHover',{model:{value:(_vm.hover),callback:function ($$v) {_vm.hover=$$v},expression:"hover"}},[_c('div',{staticClass:"px-3 rounded-sm",class:[
      { primary100: _vm.isActive },
      {
        gray100: _vm.hover && !_vm.isActive,
      },
    ]},[_c('v-row',{staticClass:"clickable",class:{
        'primary--text': _vm.isActive,
      },on:{"click":function($event){return _vm.goTo(_vm.item.path)}}},[_c('v-col',{attrs:{"cols":_vm.showSideNav && !_vm.isTransitioning ? '2' : '12',"lg":_vm.showSideNav && !_vm.isTransitioning ? '2' : '12'}},[_c('v-icon',{attrs:{"color":_vm.isActive ? 'primary' : 'gray800'},domProps:{"textContent":_vm._s(_vm.item.meta.icon)}})],1),(_vm.showSideNav && !_vm.isTransitioning)?_c('v-col',{staticClass:"navName",attrs:{"cols":"7","lg":"8"}},[_c('BaseText',{staticClass:"text-pre-line",attrs:{"color":"highEmphasis"}},[_vm._v(_vm._s(_vm.$t(_vm.item.meta.label)))])],1):_vm._e(),(_vm.showItem(_vm.item))?_c('v-spacer'):_vm._e(),(_vm.showItem(_vm.item))?_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"color":_vm.isActive ? 'primary' : 'gray1000',"small":""},domProps:{"textContent":_vm._s(_vm.isActive ? 'far fa-chevron-right' : 'far fa-chevron-down')}})],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }