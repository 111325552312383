import UserProfileRoutes from "./UserProfile";
import UserManagementRoutes from "./UserManagement";
import SeminarManagementRoutes from "./SeminarManagement";
import SeminarSpeakerRoutes from "./SeminarSpeaker";
import NavisionRoutes from "./Navision";
import HelpRoutes from "./Help";
import DashboardRoutes from "./Dashboard";
import ManagementHome from "@views/ManagementHome.vue";

export default [
  {
    path: "",
    name: "Home",
    component: ManagementHome,
    children: [
      UserProfileRoutes,
      DashboardRoutes,
      ...UserManagementRoutes,
      ...SeminarManagementRoutes,
      ...SeminarSpeakerRoutes,
      ...NavisionRoutes,
      ...HelpRoutes
    ],
  },
];
