<template>
  <BaseHover v-model="hover">
    <div
      :class="[
        { primary100: isActive },
        {
          gray100: hover && !isActive,
        },
      ]"
      class="px-3 rounded-sm"
    >
      <v-row
        @click="goTo(item.path)"
        class="clickable"
        :class="{
          'primary--text': isActive,
        }"
      >
        <v-col
          :cols="showSideNav && !isTransitioning ? '2' : '12'"
          :lg="showSideNav && !isTransitioning ? '2' : '12'"
        >
          <v-icon v-text="item.meta.icon" :color="isActive ? 'primary' : 'gray800'" />
        </v-col>

        <v-col cols="7" lg="8" class="navName" v-if="showSideNav && !isTransitioning">
          <BaseText color="highEmphasis" class="text-pre-line">{{ $t(item.meta.label) }}</BaseText>
        </v-col>

        <v-spacer v-if="showItem(item)"> </v-spacer>

        <v-col cols="2" v-if="showItem(item)" class="text-right">
          <v-icon
            :color="isActive ? 'primary' : 'gray1000'"
            small
            v-text="isActive ? 'far fa-chevron-right' : 'far fa-chevron-down'"
          />
        </v-col>
      </v-row>
    </div>
  </BaseHover>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NavItemFirst",

  props: {
    item: Object,
  },

  data() {
    return {
      hover: false,
    };
  },

  methods: {
    async goTo(route) {
      try {
        await this.$router.push(route);
      } catch {
        return;
      }
    },

    showItem(item) {
      return item.children.length > 0 && this.showSideNav && !this.isTransitioning;
    },
  },

  computed: {
    ...mapGetters("navigation", ["showSideNav", "isTransitioning"]),

    isActive() {
      return (
        this.$route.fullPath === this.item.path ||
        this.$router.history.current.matched.some((route) => route.path === this.item.path)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.navName {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
